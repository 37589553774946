<script setup lang="ts">
import { from, useObservable } from '@vueuse/rxjs'
import { map } from 'rxjs/operators'
import { ProducerApi } from '~/net/apis/producer_api'

const onClickLeft = () => history.back()

const producer = useObservable(
  from(ProducerApi.query()).pipe(map(({ data }) => data))
)
</script>

<template>
  <div class="merchant-warpper" min-h-screen>
    <van-nav-bar title="片商" left-arrow @click-left="onClickLeft" />
      <div overflow-auto class="sroll-view" px-4>
        <van-row :gutter="[20, 20]">
          <NuxtLink
          v-for="(item, index) in producer" :key="index" h-170px w-350px flex flex-col items-center justify-center class="van-cal"
          :to="`/merchant_details/${item.producerId}`">
            <ImgWrapper
            :src="`${item?.coverUrl}`"
            :preload="true"
            placeholder="./merchant_error.png"
            />
          </NuxtLink>
        </van-row>
      </div>
  </div>
</template>

<style scoped>
.sroll-view::-webkit-scrollbar{
  width: 0.2rem;
}
.van-row{
  gap:10px;
}
</style>
